import React from "react";
import LocalizedStrings from "react-localization";
import { keygen } from "./helpers";
import { getCard } from "./block.jsx";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Divider } from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
export default class Edu extends React.Component {
  constructor() {
    super();
    this.titles = new LocalizedStrings({
      en: {
        edu: "Education",
        fac: "Faculty",
        major: "Major",
        subjects: "Subjects",
      },
      fr: {
        edu: "Éducation",
        fac: "Faculté",
        major: "Major",
        subjects: "Sujects",
      },
      nl: {
        edu: "Opleiding",
        fac: "Faculteit",
        major: "Major",
        subjects: "Onderwerpen",
      },
    });
  }
  getSubjects(item) {
    return (
      <Box>
        <Typography variant="h6" gutterBottom>{this.titles.subjects}</Typography>
        <List>
          {item.school.i18n.subjects.map((t) => (
            <ListItem key={keygen()}>
              <ListItemText primary={t} />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }
  getCertification(item) {
    const cardActions = (
      <Box display="flex" alignItems="center">
        <img src={item.badgeUrl} width={64} alt="Certification Badge" />
        <Box ml={2}>
          <Typography variant="body1">{item.o} certified: {item.degree}</Typography>
        </Box>
      </Box>
    );

    const avatar = (
      <Avatar sx={{ bgcolor: "cornflowerblue" }}>
        {item.degree.charAt(0)}
      </Avatar>
    );

    return getCard(item.degree, item.o, avatar, item.degree, cardActions);
  }

  getEducation(item) {
    const title = item.school.obtained ? (
      <Typography variant="h6" fontWeight="bold">{item.school.degree}</Typography>
    ) : null;

    const subheader = (
      <Box>
        <a href={item.school.url} target="_blank" rel="noopener noreferrer">
          {item.school.o}
        </a>&nbsp;-&nbsp;
        <Typography variant="body2" color="textSecondary" component="span">
          {item.school.country}
        </Typography>
      </Box>
    );

    const avatar = (
      <Avatar sx={{ bgcolor: "cornflowerblue" }}>
        {item.school.degree.charAt(0)}
      </Avatar>
    );

    const cardContent = (
      <Box>
        {item.school.i18n.faculty && (
          <Typography variant="body2">
            {this.titles.fac}: <i>{item.school.i18n.faculty}</i>
          </Typography>
        )}
      </Box>
    );

    const cardActions = (
      <Typography variant="body2" color="text.secondary">
        {this.getSubjects(item)}
        {item.school.i18n.major && (
          <Typography variant="body2">{this.titles.major}: <i>{item.school.i18n.major}</i></Typography>
        )}
        {item.school.remark && (
          <Typography variant="body2">{item.school.degree} {item.school.remark}</Typography>
        )}
      </Typography>
    );

    return getCard(title, subheader, avatar, cardContent, cardActions);
  }

  render() {
    return (
      <Card>
        <CardHeader
          title={<Typography variant="h5" component="div">{this.titles.edu}</Typography>}
          subheader={<Typography variant="subtitle1" color="textSecondary">{`💼 ${this.titles.edu}`}</Typography>}
        />
        <CardContent>
          <Typography variant="h4" color="text.secondary" gutterBottom>
            {this.titles.edu}
          </Typography>
          {this.props.data.map((item, index) => {
            if (item.school) return this.getEducation(item);
            if (item.certification) return this.getCertification(item.certification);
            return null;
          })}
        </CardContent>
      </Card>
    );
  }
}
