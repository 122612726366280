import React from "react";

import { Avatar, Box, Card, CardHeader, CardContent, List, ListItem, ListItemText, Typography } from "@mui/material";
function svgfromjson(emoji) {
  let props = emoji.svg.props;
  let children = [];
  emoji.svg.children.map((child) => {
    children.push(React.createElement(child.type, child.props));
  });
  return React.createElement("svg", props, children);
}

function iconchecker(emoji) {
  let iconStyle = {
    maxWidth: "32px",
    minWidth: "32px",
    //maxHeight: '32px',
    padding: 0,
    margin: 0,
  };
  let i = <img style={iconStyle} />;
  if (emoji) {
    switch (typeof emoji) {
      case "object":
        if (emoji.svg) {
          //console.log('svgjson',JSON.stringify(emoji))
          i = svgfromjson(emoji);
        } else {
          i = emoji;
        }
        break;

      case "string":
        if (emoji.indexOf(";") > -1) {
          // console.log(title, 'emoji', emoji);
          let cps = emoji.split(";");
          if (cps.length > 1) {
            let h = [];
            cps.map((cp) => {
              if (cp > 1) h.push(String.fromCodePoint(cp));
            });
            //console.log(h);
            i = h.join("");
          } else {
            i = emoji;
          }
        }
        if (emoji.indexOf("http") > -1) {
          i = <img src={emoji} style={iconStyle} />;
        }
        break;
    }
  }
  return <span className="icon">{i}</span>;
}

export default function li(title, value, emoji, desc = null) {
  let i = iconchecker(emoji);
  //console.log(title, 'icon', emoji, '>>', i);
  let li = (
    <li>
      {i}
      <label alt={desc || title} title={desc || title}>
        {title} & nbsp;
      </label>
      {value}
    </li>
  );

  let span = (
    <div>
      {i}&nbsp;
      <label alt={desc || title} title={desc || title}>
        {title}
        &nbsp;
      </label>
      <span style={{ float: "right" }}>{value}</span>
    </div>
  );

  let listitem = (
    <ListItem>
      <ListItemText primary={title} secundary={desc|i}></ListItemText>{value}
    </ListItem>
  )
  return listitem;
}
