import LocalizedStrings from "react-localization";

export var data_xp = [
  {
    "public": true,
    "type": "IT",
    "company": {
      "title": "VeepeeTech",
      "type": "Dev/SRE",
      "typel": "Senior Software Engineer & SRE",
      "dep": "Monitoring",
      "city": "Full Remote",
      "country": "FR",
      "url": "https://careers.veepee.com/veepee-tech/",
      "logo": "https://careers-web.apps.veepee.com/_next/image?url=%2Fimg%2Fbotlouc.png&w=256&q=75"
    },
    "contractType": "Full-Time",
    "period": { "from": new Date("2022-06-20"), "to": new Date() },
    "techno": [
      "Python", "TypeScript", "React", "Angular", "Go",
      "Docker", "Devcontainers", "GitLab CI/CD", "Nomad",
      "Elastic API", "Grafana API", "Prometheus", "Terraform", "Ansible"
    ],
    "local": new LocalizedStrings({
      "en": {
        "jobtitle": "DevOps & Site Reliability Engineer",
        "description": "Leading DevOps and Monitoring Platform engineering for scalable, high-performance systems.",
        "tasks": [
          "Design and deploy new APIs and GUIs to integrate with internal tools, enhancing team efficiency.",
          "Collaborate with SysOps to ensure the operational stability of all deployed services.",
          "Analyze and resolve performance and scalability issues, focusing on load testing of both current and past systems.",
          "Provide daily operational support and troubleshoot critical incidents.",
          "Automate and simplify key procedures to enhance deployment speed and reliability.",
          "Promote DevOps best practices, fostering continuous improvement and scaling team capabilities."
        ]
      },
      "fr": {
        "jobtitle": "Ingénieur DevOps & SRE",
        "description": "Ingénierie de la plateforme DevOps et de surveillance pour des systèmes scalables et performants.",
        "tasks": [
          "Concevoir et déployer de nouvelles API et interfaces graphiques pour intégrer les outils internes, améliorant ainsi l'efficacité de l'équipe.",
          "Collaborer avec les SysOps pour assurer la stabilité opérationnelle des services déployés.",
          "Analyser et résoudre les problèmes de performance et d'évolutivité, en se concentrant sur les tests de charge des systèmes actuels et passés.",
          "Assurer le support opérationnel quotidien et résoudre les incidents critiques.",
          "Automatiser et simplifier les procédures clés pour améliorer la rapidité et la fiabilité du déploiement.",
          "Promouvoir les meilleures pratiques DevOps, en favorisant l'amélioration continue et l'extension des capacités de l'équipe."
        ]
      },
      "nl": {
        "jobtitle": "DevOps & SRE Engineer",
        "description": "Leiden van DevOps- en monitoringplatform engineering voor schaalbare en hoogperformante systemen.",
        "tasks": [
          "Ontwerpen en implementeren van nieuwe API's en GUI's om te integreren met interne tools, wat de efficiëntie van het team verbetert.",
          "Samenwerken met SysOps om de operationele stabiliteit van alle gedeployeerde services te waarborgen.",
          "Analyseren en oplossen van prestatie- en schaalbaarheidsproblemen, met focus op loadtesting van zowel huidige als historische systemen.",
          "Dagelijkse operationele ondersteuning bieden en kritieke incidenten oplossen.",
          "Automatiseren en vereenvoudigen van belangrijke procedures om de snelheid en betrouwbaarheid van deployments te verbeteren.",
          "DevOps-best practices bevorderen, voortdurende verbetering en schaalbaarheid van het team ondersteunen."
        ]
      }
    })
  },  
  {
    "public": true,
    "type": "IT",
    "company": {
      "title": "Orange Cloud for Business",
      "type": "Cloud",
      "typel": "Cloud and network services",
      "dep": "Infogérance",
      "city": "Rennes",
      "country": "FR",
      "url": "https://www.orange-business.com/",
      "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c8/Orange_logo.svg"
    },
    "contractType": "Full-Time",
    "period": { "from": new Date("2020-03-02"), "to": new Date("2022-05-08") },
    "techno": [
      "OpenShift",
      "Kubernetes",
      "Docker",
      "Ansible",
      "ElasticSearch",
      "OpenDistro",
      "etcd"
    ],
    "local": new LocalizedStrings({
      "en": {
        "jobtitle": "Container Engineer",
        "description": "OpenShift Expert with experience in highly secure environments",
        "tasks": [
          "Level 3 support for OpenShift and containerized environments",
          "Administration and management of OpenShift clusters",
          "Migration from OpenShift 3 to 4 in a highly secured environment",
          "Automation and simplification of cloud deployment procedures",
          "Troubleshooting and root cause analysis of containerized platforms",
          "Documentation and knowledge sharing"
        ]
      },
      "fr": {
        "jobtitle": "Ingénieur Conteneurisation",
        "description": "Expert OpenShift avec expérience dans des environnements hautement sécurisés",
        "tasks": [
          "Support niveau 3 pour OpenShift et environnements containerisés",
          "Administration et gestion des clusters OpenShift",
          "Migration d'OpenShift 3 vers 4 dans un environnement hautement sécurisé",
          "Automatisation et simplification des procédures de déploiement cloud",
          "Résolution de problèmes et analyse des causes profondes des plateformes containerisées",
          "Rédaction de documentation et partage de connaissances"
        ]
      },
      "nl": {
        "jobtitle": "Container Engineer",
        "description": "OpenShift Expert met ervaring in zeer beveiligde omgevingen",
        "tasks": [
          "Level 3 ondersteuning voor OpenShift en containeromgevingen",
          "Beheer en administratie van OpenShift clusters",
          "Migratie van OpenShift 3 naar 4 in een sterk beveiligde omgeving",
          "Automatisering en vereenvoudiging van cloud-deployments",
          "Probleemoplossing en root cause analyse van containerplatforms",
          "Documentatie en kennisdeling"
        ]
      }
    })
  ,  
    urls: [
      /*{
    title: 'SPaaS',
    url: 'https://console.paas.sigma.fr'
  },*/
    ],
  },
  {
    "public": true,
    "type": "IT",
    "company": {
      "title": "Groupe Sigma",
      "type": "Cloud",
      "typel": "Cloud and network services",
      "dep": "Infogérance",
      "city": "Nantes",
      "country": "FR",
      "url": "https://www.sigma.fr",
      "logo": "https://upload.wikimedia.org/wikipedia/fr/2/21/Logo_Groupe_Sigma.png"
    },
    "contractType": "Full-Time",
    "period": { "from": new Date("2019-05-13"), "to": new Date("2019-12-12") },
    "techno": ["OpenShift", "Docker", "Ansible", "Kubernetes", "Azure"],
    "local": new LocalizedStrings({
      "en": {
        "jobtitle": "DevOps Integrator",
        "description": "Managing and automating cloud services (public and private)",
        "tasks": [
          "Customized Docker images for OpenShift environments",
          "End-to-end cloud integration",
          "Cloud monitoring and performance optimization",
          "Automation of public cloud workflows",
          "Software development in cloud environments"
        ]
      },
      "fr": {
        "jobtitle": "Intégrateur DevOps",
        "description": "Gestion et automatisation des services cloud (publics et privés)",
        "tasks": [
          "Création d'images Docker sur mesure pour OpenShift",
          "Intégration cloud de bout en bout",
          "Surveillance et optimisation des performances cloud",
          "Automatisation des flux de travail cloud public",
          "Développement logiciel dans des environnements cloud"
        ]
      },
      "nl": {
        "jobtitle": "DevOps Integrator",
        "description": "Beheer en automatisering van cloud services (privé en openbaar)",
        "tasks": [
          "Maatwerk Docker images voor OpenShift omgevingen",
          "End-to-end cloud integratie",
          "Cloud monitoring en prestatie-optimalisatie",
          "Automatisering van openbare cloud workflows",
          "Softwareontwikkeling in cloudomgevingen"
        ]
      }
    }),
    "urls": [
      {
        "title": "Powershell generator for Azure template deployment",
        "url": "https://github.com/jeanphilipperuijs/arm2ps1"
      }
    ]
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Télécom ParisTech",
      type: "Grande École",
      typel: "French public institutions of higher education and research",
      dep: "Image, Données, Signal (IDS)",
      city: "Paris",
      country: "FR",
      url: "http://www.imt.fr",
      logo: "https://images.theconversation.com/institutions/2346/logos/logo-1559577082.png?ixlib=rb-1.1.0&q=45&auto=format&w=170&h=170",
    },
    contractType: "Full-Time",
    period: { from: new Date("2016-06-13"), to: new Date("2017-12-12") },
    techno: [
      "HbbTV", "JavaScript", "NodeJS", "ReactJS", "WordPress"
    ],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Research Scientist",
        description: "European open source project",
        tasks: [
          "Horizon 2020: Collaborated with Fraunhofer FOKUS, IRT, Télécom ParisTech, Lancaster University, and Fincons on HbbTV and MPAT.",
          "Teaching Software Engineering",
          "Supervisor 1st Year Master Expert Test & Integration",
          "Practical Work 2nd Year Master JavaScript",
        ],
      },
      fr: {
        jobtitle: "Ingénieur de recherche / Chargés d’Etudes",
        description: "Projet européen logiciel libre",
        tasks: [
          "Horizon 2020 : Collaboration avec Fraunhofer FOKUS, IRT, Télécom ParisTech, Lancaster University et Fincons sur HbbTV et MPAT.",
          "Encadrant 1ere annéee Master Expert Test & Intégration",
          "Travaux Pratiques JavaScript 2eme année Master ",
        ],
      },
      nl: {
        jobtitle: "Onderzoekswetenschapper",
        description: "Europees open source project",
        tasks: [
          "Horizon 2020: Samenwerking met Fraunhofer FOKUS, IRT, Télécom ParisTech, Lancaster University en Fincons over HbbTV en MPAT.",
          "Begeleider 1ste jaar Master Expert Test & Integratie",
          "Praktijkwerk 2de jaar Master JavaScript",
        ],
      },
    }),  
    urls: [
     /* {
        title: "Télécom ParisTech - Groupe MultiMédia -",
        url: "http://www.tsi.telecom-paristech.fr/mm/themes/equipe-gpac/",
      },*/
      { title: "MPAT Project Home Page", url: "http://mpat.eu" },
      { title: "MPAT Project Github", url: "https://github.com/mpat-eu" },
      /* {
       title: 'MPAT Plugins', url:
         'https://github.com/jeanphilipperuijs'
     },*/
      {
        title: "GitLab Fraunhofer",
        url: "https://gitlab.fokus.fraunhofer.de/jp.ruijs",
      },
 /*     {
        title: "MPAT Coders Meeting at ULANC",
        url: "https://twitter.com/MPAT_project/status/768897594395353089",
      } ,
    { title: 'PACT', url: 'https://pact.wp.imt.fr/vitrine/projets-2016-2017/' }
*/,
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Freelance",
      type: "Data Visualization",
      city: "Zeist",
      country: "NL",
    },
    contractType: "Freelance",
    period: { from: new Date("2015-01-01"), to: new Date("2016-05-01") },
    techno: ["Python", "Django", "MySQL", "D3JS", "Java"],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        description: "Various projects including GIS Animation System improvements",
        tasks: ["Data Visualization", "Dataset assessment", "System enhancement"],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        description: "Divers projets dont l'amélioration du système d'animation SIG",
        tasks: ["Visualisation de données", "Évaluation des données", "Amélioration du système"],
      },
      nl: {
        jobtitle: "Software Engineer",
        description: "Diverse projecten, waaronder verbeteringen aan het GIS Animatiesysteem",
        tasks: ["Gegevensvisualisatie", "Datasetbeoordeling", "Systeemverbetering"],
      },
    }),
  },
  {
    public: false,
    type: "IT",
    company: {
      title: "DutchWorld Solutions",
      city: "Amersfoort",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: { jobtitle: "Software Architect", tasks: ["eCommerce"] },
      fr: { jobtitle: "Architect Logiciel", tasks: ["eCommerce"] },
    }),
    period: { from: new Date("2015-11-01"), to: new Date("2015-12-01") },
    techno: ["odoo", "Amazon Web Service", "Magento", "Docker"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Sensoterra",
      type: "Start-Up",
      city: "Amsterdam",
      country: "NL",
    },
    contractType: "Freelance",
    url: "http://www.sensoterra.com/",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Hardware Engineer",
        description: "Rugged prototype",
        tasks: [
          "Improved Beagle-based Prototype Assembly",
          "Shock resistance, high temperature, sand storm and tightness",
          "Verification of electrical components and their assembly",
          "Strengthening weak links.",
        ],
      },
      fr: {
        jobtitle: "Assembleur",
        tasks: [
          "Amélioration de l'assemblage de prototype basé sur Beagle",
          "Resistance au choque, haute temperature, tempête de sable et étanchité.",
          "Vérification des composants électrique et de leur montage.",
          "Renforcement des maillons faibles.",
        ],
      },
    }),
    period: { from: new Date("2015-10-01"), to: new Date("2015-10-31") },
    techno: ["BeagleBoard", "GSM", "3G"],
    urls: [
      { title: "Land Life Company", url: "http://www.landlifecompany.com/" },
      {
        title: "Demo video",
        url: "https://www.youtube.com/watch?v=yRtlE2S1wIY",
      },
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "IP-label",
      type: "Application Performance Monitoring",
      city: "La Garenne-Colombes",
      country: "FR",
      postalcode: "92250",
      logo: "https://ip-label.com/wp-content/uploads/2024/07/cropped-ekara_colors_logo.png",
    },
    urls: [
      { title: "ip-label Wiki NEWTEST", url: "https://ip-label.com" },
    ],
    contractType: "Full-Time",
    period: { from: new Date("2014-06-01"), to: new Date("2015-04-01") },
    techno: [
      "Newtest", "Windows Server Core", "DVB", "IEEE 802", "Digital Imaging"
    ],
    local: new LocalizedStrings({
      en: {
        jobtitle: "Support Engineer",
        description: "Troubleshooting and client support",
        tasks: [
          "Analyze and resolve client issues in a timely manner",
          "Provide recommendations and action plans to clients",
          "Maintain high confidentiality standards",
          "Review and verify source code to identify and fix bugs"
        ],
      },
      nl: {
        jobtitle: "Support Engineer",
        description: "Dépannage et support client",
        tasks: [
          "Analyseer en los klantproblemen snel op",
          "Adviseer en begeleid klanten met aanbevelingen et actieplannen",
          "Waarborg vertrouwelijkheid",
          "Controleer en verifieer broncode om bugs op te lossen"
        ],
      },
      fr: {
        jobtitle: "Ingénieur Support",
        description: "Dépannage et support client",
        tasks: [
          "Analyser et résoudre les problèmes des clients dans les plus brefs délais",
          "Proposer des recommandations et plans d'action aux clients",
          "Maintenir des normes strictes de confidentialité",
          "Vérifier et analyser le code source pour corriger les bugs"
        ],
      },
    }),
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Ministerie van Onderwijs, Cultuur en Wetenschap",
      city: "Den-Haag",
      country: "NL",
      type: "Dutch Ministry",
      logo: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Logo_rijksoverheid.svg",
    },
    contractType: "Freelance",
    period: { from: new Date("2014-02-01"), to: new Date("2014-08-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        description: "Developing interactive data visualizations for educational projects",
        tasks: [
          "Optimizing large datasets for real-time processing",
          "Creating interactive, bidirectional data visualizations",
          "Maintaining hosting environment and ensuring performance",
        ],
      },
      fr: {
        jobtitle: "Développeur Logiciel",
        description: "Développement de visualisations interactives de données pour des projets éducatifs",
        tasks: [
          "Optimisation des jeux de données pour le traitement en temps réel",
          "Création de visualisations de données bidirectionnelles interactives",
          "Maintien de l'environnement d'hébergement et optimisation des performances",
        ],
      },
      nl: {
        jobtitle: "Software Ontwikkelaar",
        description: "Ontwikkeling van interactieve datavisualisaties voor onderwijsprojecten",
        tasks: [
          "Optimalisatie van grote datasets voor real-time verwerking",
          "Creëren van interactieve bidirectionele datavisualisaties",
          "Beheren van hosting en optimaliseren van prestaties",
        ],
      },
    }),
    techno: ["D3JS", "Python"],
  },   
  {
    public: true,
    type: "IT",
    company: {
      title: "VPRO Nederland van Boven",
      type: "Dutch Public Television",
      city: "Hilversum",
      country: "NL",
      logo: "https://upload.wikimedia.org/wikipedia/commons/6/62/VPRO_logo.svg",
    },
    contractType: "Freelance",
    period: { from: new Date("2012-01-01"), to: new Date("2013-12-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Architect",
        description: "Designing and developing the Geographical Animation System",
        tasks: [
          "Dataset conversion for geospatial data",
          "Designing animation framework",
          "Generating animation frames",
          "Video encoding for broadcast",
          "Hosting and maintaining infrastructure",
        ],
      },
      fr: {
        jobtitle: "Architecte logiciel",
        description: "Conception et développement du système d'animation géographique",
        tasks: [
          "Conversion des jeux de données géospatiales",
          "Conception du framework d'animation",
          "Génération des trames d'animation",
          "Encodage vidéo pour la diffusion",
          "Hébergement et maintenance de l'infrastructure",
        ],
      },
      nl: {
        jobtitle: "Software Architect",
        description: "Ontwerpen en ontwikkelen van het Geografisch Animatiesysteem",
        tasks: [
          "Dataset-conversie voor geospatiale gegevens",
          "Ontwerpen van animatieframework",
          "Genereren van animatietrames",
          "Videocodering voor uitzending",
          "Hosting en infrastructuuronderhoud",
        ],
      },
    }),
    urls: [
     { title: "Credits", url: "./img/unused/20140110_231232_climax.png" },
     { title:'Steden groeien',url:"https://www.youtube.com/watch?v=Q7QVsvwXXg8"},
     { title: 'Dredging Maasvlakte 2',
      url: 'https://www.youtube.com/watch?v=LxtzPWWHi60&list=PLuJ8GxssWxHBAVX-RRD9hacJo17zRM2Y8&index=26'
     },
     { title: 'Building of the Netherlands since the medivals',
      url: 'https://www.youtube.com/watch?v=10LoZrI4m4I&index=4&list=PLuJ8GxssWxHBAVX-RRD9hacJo17zRM2Y8'
     }
    ],
    period: { from: new Date("2013-02-01"), to: new Date("2014-06-01") },
    techno: ["Java", "Python", "GIS", "NMEA", "WGS84", "RDS"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "CNRS DSI",
      type: "French National Center for Scientific Research",
      city: "Meudon",
      country: "FR",
      logo:
        "https://upload.wikimedia.org/wikipedia/fr/8/8e/Centre_national_de_la_recherche_scientifique.svg",
    },
    period: { from: new Date("2010-11-01"), to: new Date("2012-11-01") },
    local: new LocalizedStrings({
      fr: {
        jobtitle: "Ingénieur d'études",
        contractType: "CDD",
        description: "Assurance qualité, Recherche et Développement",
        tasks: [
          "Développement de maquettes",
          "Analyse des moniteurs des grilles de calcul",
          "Pilotage de projet",
        ],
      },
      en: {
        jobtitle: "Software Engineer",
        contractType: "Full-Time",
        description: "Quality Assurance, Research & Development",
        tasks: [
          "Proof-of-Concept creation",
          "Reverse-engineering analysis",
          "Prototyping new solutions",
          "Pilot experiments design and execution",
        ],
      },
      nl: {
        jobtitle: "Software Engineer",
        contractType: "Voltijd",
        description: "Kwaliteitsgarantie, Onderzoek & Ontwikkeling",
        tasks: [
          "Proof-of-Concept ontwikkeling",
          "Reverse-engineering analyse",
          "Prototyping van nieuwe oplossingen",
          "Pilotproject ontwerp en uitvoering",
        ],
      },
    }),
    techno: [
      "C",
      "C#",
      'Perl',
      "Python",
      "Java",
      "SharePoint" ,'OpenLDAP', 'MySQL',
      /*  'Tomcat', 'JavaEE', */
      'JPA & Hibernate',
      /*'Play!', 'Groovy', 'Android',*/
      'perfSONAR', 
    ],
    urls: [/*
      { title: "CoRe", url: "https://ods.cnrs.fr/portail_core.php" },
      { title: "CTA Observatory", url: "https://www.cta-observatory.org/" },
       */
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Smile Open-Source Solutions",
      city: "Levallois-Perret",
      country: "FR",
      type: "Open-Source Solutions",
      url: "http://www.smile.eu",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        contractType: "Full-Time",
        description: "Web dev",
        tasks: ["WebService", "Multi-browser support"],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        contractType: "CDI",
        description: "Web Dev",
        tasks: ["Développement services web"],
      },
      nl: {
        jobtitle: "Software Ontwikkelaar",
        contractType: "Voltijd",
        tasks: ["WebService", "Multi-browser support"],
      },
    }),
    period: { from: new Date("2010-09-01"), to: new Date("2010-10-01") },
    techno: ["JavaEE", "Spring", "Struts", "Hibernate", "HTML"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Buongiorno Spa.",
      type: "Mobile Services",
      city: "Paris",
      country: "FR",
      url: "https://www.buongiorno.com/",
    },
    urls: [
      {
        title: "Wikipedia",
        url: "https://en.wikipedia.org/wiki/Buongiorno_(company)",
      },
    ],
    period: { from: new Date("2009-05-13"), to: new Date("2010-08-15") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        contractType: "Full-Time",
        description:
          "Developing and maintaining B2B mobile, web, and SMS services for clients.",
        tasks: [
          "Developed and maintained client web interfaces",
          "Designed and managed SMS service integrations",
          "Created Proof-of-Concepts for service enhancements",
          "Administered B2B services for clients and partners",
        ],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        contractType: "CDI",
        description:
          "Développement et maintenance de services B2B mobiles, Web et SMS pour les clients",
        tasks: [
          "Refonte du site SFR",
          "Conception et gestion des services SMS",
          "Création de Proof-of-Concepts",
          "Administration des services B2B pour les clients",
        ],
      },
      nl: {
        jobtitle: "Software Engineer",
        contractType: "Voltijd",
        description:
          "Ontwikkelen en onderhouden van B2B mobiele, web- en sms-diensten voor klanten",
        tasks: [
          "Ontwikkelen en onderhouden van client web-interfaces",
          "Ontwerpen en beheren van SMS-diensten",
          "Proof-of-Concepts maken",
          "Administreren van B2B-diensten voor klanten",
        ],
      },
    }),
    techno: [
      "Java",
      "Linux",
      "Tomcat",
      "SMS",
      "MySQL",
      /*"OML",
      "PML",
      "WML",
      "HTML",*/
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "DirectMovie",
      type: "Video-On-Demand",
      city: "Goirle/Hilversum",
      country: "NL",
    },
    period: { from: new Date("2007-07-01"), to: new Date("2008-02-15") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        contractType: "Graduation Project",
        description: "Developed a proof-of-concept for overlay ads in video-on-demand platforms.",
        tasks: [
          "Prototyping video overlay ads",
          "Analyzed technical feasibility and alternatives",
          "Developed and tested video-on-demand overlay proof-of-concept"
        ],
      },
      fr: {
        jobtitle: "Ingénieur d'études",
        contractType: "Projet de fin d'études",
        description: "Création de publicités superposées pour la vidéo à la demande",
        tasks: [
          "Prototypage des publicités superposées",
          "Analyse de la faisabilité technique et des alternatives",
          "Création de maquettes de publicité pour la Vidéo-à-la-Demande"
        ],
      },
      nl: {
        jobtitle: "Ontwikkelingsingenieur",
        contractType: "Afstudeerproject",
        description: "Overlay advertenties voor video-on-demand systemen ontwikkeld",
        tasks: [
          "Prototyping van overlay-advertenties",
          "Technische haalbaarheid en alternatieven onderzocht",
          "Proof-of-concept voor VoD overlay ontwikkeld"
        ],
      },
    }),
    techno: [
      "C#",
      "SilverLight 1.1 alpha", 
      "Windows Media Server", 
      "Visual Studio",
      "Windows Server", 
      "DRM"
    ],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Figi",
      url: "http://www.figi.com",
      type: "Theater",
      city: "Zeist",
      country: "NL",
    },
    period: { from: new Date("2006-06-01"), to: new Date("2009-02-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Software Engineer",
        contractType: "Freelance",
        description: "Developed back-end and front-end components for image analysis and search engines.",
        tasks: [
          "Back-end & Front-end development",
          "Image analysis and indexing",
          "Image search engine architecture and development",
          "Collaborated with teams to enhance image processing algorithms",
        ],
      },
      nl: {
        jobtitle: "Systeemingenieur",
        contractType: "Zelfstandig",
        description: "Ontwikkeling van back-end en front-end voor beeldanalyse en zoekmachines.",
        tasks: [
          "Back-end & Front-end ontwikkeling",
          "Beeldanalyse en indexering",
          "Zoekmachine architectuur en ontwikkeling",
          "Samenwerking met teams om beeldverwerkingsalgoritmes te verbeteren",
        ],
      },
      fr: {
        jobtitle: "Ingénieur de système",
        contractType: "Indépendant",
        description: "Développement du back-end et front-end pour l'analyse d'images et les moteurs de recherche.",
        tasks: [
          "Développement Back-end & Front-end",
          "Analyse et indexation d'images",
          "Conception et développement de moteurs de recherche",
          "Collaboration avec les équipes pour améliorer les algorithmes de traitement d'images",
        ],
      },
    }),
    techno: [
      "Java", "MySQL", "PHP", "Python", "JavaScript", "Django"
    ],
  },  
  {
    public: true,
    type: "IT",
    company: {
      title: "RIAGG Zwolle",
      url: "https://www.dimence.nl/locaties/burgemeester-roelenweg",
      type: "Mental Health Service",
      city: "Zwolle",
      country: "NL",
    },
    period: { from: new Date("2005-02-01"), to: new Date("2005-12-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "System & Network Administrator",
        contractType: "Consultant",
        description: "Ensure the security and restoration of network and workstation backups.",
        tasks: [
          "System administration and maintenance",
          "Network infrastructure management and optimization",
          "Monitor and troubleshoot network issues",
          "Back-up configuration and restoration",
          "Collaborate with team to improve IT security protocols"
        ],
      },
      nl: {
        jobtitle: "Systeem- en netwerkbeheerder",
        contractType: "Consultant",
        description: "Beheer van systeem- en netwerkbeveiliging en back-ups.",
        tasks: [
          "Systeembeheer en onderhoud",
          "Beheer en optimalisatie van netwerkinfrastructuur",
          "Netwerkproblemen monitoren en oplossen",
          "Back-up configuratie en herstel",
          "Samenwerken om IT-beveiligingsprotocollen te verbeteren"
        ],
      },
      fr: {
        jobtitle: "Administrateur Système et Réseau",
        contractType: "Consultant",
        description: "Gestion de la sécurité et de la restauration des sauvegardes réseau et des stations de travail.",
        tasks: [
          "Gestion et maintenance des systèmes",
          "Gestion et optimisation des infrastructures réseau",
          "Surveillance et résolution des problèmes réseau",
          "Configuration et restauration des sauvegardes",
          "Collaboration pour améliorer les protocoles de sécurité informatique"
        ],
      },
    }),
    techno: ["VoIP", "Novell", "PXE", "Norton Ghost"],
  },  
  {
    public: true,
    type: "IT",
    company: {
      title: "Reinaerde",
      url: "https://www.reinaerde.nl/",
      type: "Mental Health Service",
      city: "Utrecht",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Consultant",
        tasks: ["System management", "Network management"],
      },
      fr: {
        jobtitle: "Consultant",
        tasks: ["Gestion de système", "Gestion de réseau"],
      },
      nl: { jobtitle: "Consultant", tasks: ["Systeembeheer", "Netwerkbeheer"] },
    }),
    period: { from: new Date("2005-10"), to: new Date("2005-11") },
    techno: ["Microsoft", "Novell", "PXE"],
  },
  {
    public: true,
    type: "Photo",
    company: {
      title: "Hogeschool Utrecht",
      url: "http://www.hu.nl",
      type: "Education",
      city: "Utrecht",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Digital Photo Specialist",
        tasks: ["Preparing photos for new site"],
      },
      fr: {
        jobtitle: "Digital Photo Specialist",
        tasks: ["Preparing photos for new site"],
      },
      nl: {
        jobtitle: "Digital Photo Specialist",
        tasks: ["photos for new site"],
      },
    }),
    period: { from: new Date("2005-08"), to: new Date("2005-08") },
    techno: ["Photoshop"],
  },
  {
    public: true,
    type: "summerjob",
    company: {
      title: "Brittany Ferries",
      type: "Passenger Ferries",
      city: "Roscoff",
      country: "F",
    },
    contractType: "Temp",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Handlin",
        contractType: "Summer job",
        tasks: ["Pork derived production"],
      },
      fr: {
        contractType: "Travail d'été",
        jobtitle: "Agent de néttoyage",
        tasks: ["Préparation des cabines"],
      },
      nl: {
        jobtitle: "Behandeling",
        contractType: "Zomerbaan",
        tasks: ["Van varkensvlees afkomstige productie"],
      },
    }),
    period: { from: new Date("2005-07"), to: new Date("2005-08") },
    techno: ["none"],
  },
  {
    public: false,
    type: "usine",
    company: {
      title: "Jean Caby",
      type: "Salaison",
      city: "Lampaul-Guimiliau",
      country: "F",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Handling",
        contractType: "Summer job",
        tasks: ["Pork derived production"],
      },
      fr: {
        jobtitle: "Manutention",
        contractType: "Travail d'été",
        tasks: ["Production dérivé de porc"],
      },
      nl: {
        jobtitle: "Behandeling",
        contractType: "Zomerbaan",
        tasks: ["Van varkensvlees afkomstige productie"],
      },
    }),
    period: { from: new Date("2005-07"), to: new Date("2005-08") },
    techno: ["none"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "Eneco",
      url: "http://www.ogd.nl",
      type: "Consultancy",
      city: "Utrecht",
      country: "NL",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "System administrator",
        contractType: "Consultant",
        tasks: ["System management", "Network management"],
      },
      fr: {
        jobtitle: "Administrateur système",
        contractType: "Consultant",
        tasks: ["Gestion de système", "Gestion de réseau"],
      },
      nl: {
        jobtitle: "Systeembeheerder",
        contractType: "Consultant",
        tasks: ["Systeembeheer", "Netwerkbeheer"],
      },
    }),
    period: { from: new Date("2005-06"), to: new Date("2005-06") },
    techno: ["VoIP"],
  },
  {
    public: true,
    type: "media",
    company: {
      title: "Algemene Media Groep",
      type: "Advertising",
      city: "Rotterdam",
      country: "NL",
    },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Order processing",
        contractType: "Summer job",
        tasks: ["Bulk Data Entry", "Advertisements"],
      },
      fr: {
        jobtitle: "Traitement des commandes",
        contractType: "Saisonnier",
        tasks: ["Saisi de données en masse", "Publicités"],
      },
      nl: {
        jobtitle: "Orderverwerking",
        contractType: "Zomerbaan",
        tasks: ["Bulk gegevensinvoer", "Advertenties"],
      },
    }),
    period: { from: new Date("2004-07"), to: new Date("2004-08") },
    techno: ["Windows", "Terminal", "Fax", "Print"],
  },
  {
    public: true,
    type: "IT",
    company: {
      title: "The BOX Holland BV",
      type: "Music Video On Demand",
      city: "Hilversum",
      country: "NL",
      url: "http://www.thebox.nl",
    },
    period: { from: new Date("2003-08-01"), to: new Date("2004-06-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Webmaster Intern",
        contractType: "Internship",
        description: "Migrating and upgrading website infrastructure for a music video on demand platform.",
        tasks: [
          "Installed Solaris operating system on new hardware infrastructure.",
          "Integrated content management system (CMS) for website updates and management.",
          "Collaborated with the team to ensure smooth migration and functionality.",
          "Performed website maintenance and optimization to enhance user experience."
        ],
      },
      nl: {
        jobtitle: "Web Meester",
        contractType: "Stage",
        description: "Migratie en verbetering van de website-infrastructuur voor een muziekvideo-on-demand platform.",
        tasks: [
          "Installatie van Solaris op nieuwe servers.",
          "Integratie van CMS voor website-updates.",
          "Samenwerking met het ontwikkelingsteam om de migratie te waarborgen.",
          "Onderhoud en optimalisatie van de website voor betere gebruikerservaring."
        ],
      },
      fr: {
        jobtitle: "Webmaster Stagiaire",
        contractType: "Stage",
        description: "Migration et mise à niveau du site Web pour une plateforme de vidéos à la demande.",
        tasks: [
          "Installation de Solaris sur l'infrastructure matérielle.",
          "Intégration d'un système de gestion de contenu (CMS) pour la mise à jour du site.",
          "Collaboration avec l’équipe technique pour assurer la migration.",
          "Maintenance et optimisation du site pour améliorer l’expérience utilisateur."
        ],
      },
    }),
    techno: ["Solaris", "JSP", "MySQL", "PHP"],
  },  
  {
    public: true,
    type: "IT",
    company: {
      title: "Figi",
      type: "Conference Centre",
      url: "http://www.figi.nl",
      city: "Zeist",
      country: "NL",
    },
    period: { from: new Date("2000-02-01"), to: new Date("2001-09-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Network Administrator",
        contractType: "Full-Time",
        description: "Responsible for managing network infrastructure, system administration, and workstation support.",
        tasks: [
          "Migrated legacy systems to modern platforms ensuring minimal downtime.",
          "Implemented network solutions to optimize communication and operational efficiency.",
          "Maintained and supported critical IT infrastructure, including servers, workstations, and network equipment.",
          "Monitored system performance and applied patches for security and stability."
        ],
      },
      nl: {
        jobtitle: "Netwerkbeheerder",
        contractType: "Voltijd",
        description: "Verantwoordelijk voor het beheren van netwerkinfrastructuur, systeembeheer en werkstationondersteuning.",
        tasks: [
          "Migratie van verouderde systemen naar moderne platforms met minimale downtime.",
          "Implementatie van netwerkinstellingen ter optimalisatie van communicatie en operationele efficiëntie.",
          "Ondersteuning en onderhoud van kritieke IT-infrastructuur, inclusief servers en werkstations.",
          "Systeemmonitoring en implementatie van beveiligings- en stabiliteitsupdates."
        ],
      },
      fr: {
        jobtitle: "Administrateur Réseau",
        contractType: "Temps Plein",
        description: "Gestion de l'infrastructure réseau, administration des systèmes et support des postes de travail.",
        tasks: [
          "Migration des systèmes hérités vers des plateformes modernes avec un minimum d'interruption.",
          "Mise en place de solutions réseau pour améliorer la communication et l'efficacité opérationnelle.",
          "Maintenance et support de l'infrastructure IT, y compris les serveurs et les postes de travail.",
          "Surveillance de la performance des systèmes et application de patchs pour la sécurité et la stabilité."
        ],
      },
    }),
    techno: ["Novell NetWare", "SCO Unix", "Exchange", "TCP/IP","IPX/SPX"],
  },  
  {
    public: true,
    type: "IT",
    company: {
      title: "BuitenDeDeur",
      type: "Start-Up",
      city: "Utrecht",
      url: "http://www.buitendedeur.nl",
      country: "NL",
    },
    period: { from: new Date("1999-01-01"), to: new Date("2000-01-01") },
    local: new LocalizedStrings({
      en: {
        jobtitle: "Webmaster",
        contractType: "Freelance",
        description: "End-to-end website development for an early-stage start-up.",
        tasks: [
          "Designed and developed the start-up's initial website to establish online presence.",
          "Architected the database schema and back-end functionality to support dynamic content.",
          "Implemented a user-friendly graphical interface with a focus on seamless navigation and responsive design.",
          "Collaborated with the client to ensure alignment with branding and business goals."
        ],
      },
      nl: {
        jobtitle: "Webmaster",
        description: "Ontwikkeling en lancering van de website voor een start-up.",
        tasks: [
          "Ontwikkeling van de start-up website voor een online aanwezigheid.",
          "Architectuur van de database en back-end functionaliteit.",
          "Implementatie van een gebruiksvriendelijke interface voor een responsief ontwerp.",
          "Samenwerking met de klant om de website te aligneren met merk- en bedrijfsdoelen."
        ],
      },
      fr: {
        jobtitle: "Webmaster",
        description: "Développement et lancement du site web pour une start-up.",
        tasks: [
          "Développement du site web de l'entreprise pour une présence en ligne.",
          "Conception de la base de données et des fonctionnalités back-end.",
          "Mise en œuvre d'une interface graphique intuitive pour un design réactif.",
          "Collaboration étroite avec le client pour assurer l'alignement avec les objectifs commerciaux."
        ],
      },
    }),
    techno: ["PHP", "MySQL", "HTML", "CSS", "JavaScript"],
  },  
  {
    public: true,
    type: "IT",
    company: {
      title: "Social Werkvoorziening Zeist",
      url: "http://www.swzeist.nl",
      type: "Social Work Organization",
      city: "Zeist",
      country: "NL",
    },
    contractType: "Freelance",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Webmaster",
        description: "Website development and lifecycle management for a public service organization.",
        tasks: [
          "Planned and executed the initial launch of the organization's website.",
          "Designed and implemented user-friendly navigation and a responsive layout.",
          "Developed back-end features using PHP and MySQL for dynamic content management.",
          "Set up secure hosting environments and managed ongoing server maintenance.",
          "Collaborated with stakeholders to update content and align with branding goals."
        ],
      },
      fr: {
        jobtitle: "Webmaster",
        description: "Développement et gestion de la vie du site web pour une organisation publique.",
        tasks: [
          "Planification et lancement initial du site web.",
          "Création d'une navigation ergonomique et d'une mise en page adaptative.",
          "Développement des fonctionnalités dynamiques avec PHP et MySQL.",
          "Mise en place de l'hébergement sécurisé et maintenance des serveurs.",
          "Collaboration avec les parties prenantes pour actualiser le contenu."
        ],
      },
      nl: {
        jobtitle: "Webmaster",
        description: "Ontwikkeling en levenscyclusbeheer van een website voor een publieke dienstorganisatie.",
        tasks: [
          "Planning en lancering van de eerste versie van de website.",
          "Ontwerp van gebruiksvriendelijke navigatie en responsieve layout.",
          "Ontwikkeling van back-end functionaliteit met PHP en MySQL.",
          "Opzetten van veilige hostingomgevingen en serveronderhoud.",
          "Samenwerken met belanghebbenden voor inhoudelijke updates."
        ],
      },
    }),
    period: { from: new Date("1999-01-01"), to: new Date("2001-01-01") },
    techno: ["PHP", "MySQL", "HTML", "CSS", "JavaScript"],
  },
  {
    public: true,
    type: "horeca",
    company: {
      title: "Golden Tulip Barbizon",
      type: "Hotel",
      city: "Maastricht",
      country: "NL",
    },
    contractType: "Internship",
    local: new LocalizedStrings({
      en: {
        jobtitle: "Duty Manager",
        description: "Keep the hotel well oiled and running",
        tasks: ["Room Service", "Stewarding", "Banqueting", "Restaurant"],
      },
      fr: {
        jobtitle: "Gestionnaire de service",
        description: "All-Round",
        tasks: ["Service de chambre", "Stewarding", "Banquet", "Restaurant"],
      },
      nl: {
        jobtitle: "Duty Manager",
        description: "All-Round",
        tasks: ["Room Service", "Stewarding", "Banqueting", "Restaurant"],
      },
    }),
    period: { from: new Date("1998-08"), to: new Date("1999-02") },
    techno: ["Social Engineering", "Novell", "Excel", "Pegasus Mail"],
  },
];
