import React from "react";
import LocalizedStrings from "react-localization";
import { Avatar, Box, Card, CardHeader, CardContent, Typography } from "@mui/material";
import { getCard } from "./block.jsx";
import QRCode from "react-qr-code";

import showItem from "./listitem.jsx";
import { getAge } from "./calcage.js";
import hobbyList from "./data/data_hobbies.js";
import { setCookie, getCookie, vape, delCookie } from "./koekie/monster.js";
import { host, keygen, antispammail } from "./helpers.js";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.i18n = new LocalizedStrings(this.props.data.i18n);
    this.titles = new LocalizedStrings({
      en: {
        edu: "Education",
        fac: "Faculty",
        major: "Major",
        subjects: "Subjects",
      },
      fr: {
        edu: "Éducation",
        fac: "Faculté",
        major: "Major",
        subjects: "Sujects",
      },
      nl: {
        edu: "Opleiding",
        fac: "Faculteit",
        major: "Major",
        subjects: "Onderwerpen",
      },
    });
    this.i18n.setLanguage(this.props.la || this.i18n.getLanguage());
    this.tempkey = getCookie("rui");
  }

  getContact() {
    let perso = this.props.data.perso;
    let urls = this.props.data.urls;
    let bcc = `bcc=${perso.contact.mail[1]},${perso.contact.mail[2]}&`;
    return (
      <Box>
        <span className="title sub">
          <span className="icon">&#x1F50C;</span>Contact
        </span>
        <div>
          {showItem(
            "URL",
            <a href={urls.cv} title="web site">
              {urls.cv.split("//")[1]}
            </a>,
            "0x1F517;"
          )}

          {showItem(
            "Mail",
            <a href={`mailto:${perso.contact.mail[0]}?${bcc}subject=CV`}>
              {antispammail(perso.contact.mail[0])}
            </a>,
            "0x2709;"
          )}

          {showItem(
            "Phone",
            <a
              title="Mobile Phone"
              href={`tel:${perso.contact.phone.split(" ").join("")}`}
            >
              {perso.contact.phone}
            </a>,
            "0x2706;"
          )}

          {/*
                    showItem('Skype',
                        <a title="Skype" href={`callto:${perso.contact.skype}`}>{perso.contact.skype}</a>,
                        <img style={{ width: '16px' }} src={urls.skypeicon} />) 
        */}
        </div>
      </Box>
    );
  }

  getLangaugeSkills() {
    return (
      <span>
        <span className="title sub">
          <span className="icon">&#x1F5E3;&#xFE0F;</span>
          {this.i18n.langskill}
        </span>
        <div>
          {this.i18n.languages.map((la) => {
            return showItem(la.title, la.value, la.icon, la.desc);
          })}
        </div>
      </span>
    );
  }

  getAge() {
    return showItem(
      this.i18n.age,
      <span>
        {getAge(this.props.data.perso.dob)} {this.i18n.years}
      </span>,
      <span>&#x1F382;</span>
    );
  }

  getPersonal() {
    return (
      <div>
        <span className="title sub">
          <span className="icon">
            &#x1F468;&#x200D;&#x1F469;&#x200D;&#x1F467;&nbsp;
          </span>
          Personal
        </span>
        <div>
          {showItem("Location", this.i18n.location, <span>&#x1F307;</span>)}
          {showItem(
            this.i18n.dateOfBirth,
            new Date(this.props.data.perso.dob).toLocaleDateString(),
            <span>&#x1F382;</span>
          )}
          {this.getAge()}
        </div>
      </div>
    );
  }

  getRef() {
    return (
      <div>
        <span className="title sub">
          <span className="icon">&#x1F517;</span>References
        </span>
        <div>
          {this.props.data.references.map((ref) => {
            return showItem(
              ref.title,
              <a href={ref.url} target="_blank">
                {ref.desc}
              </a>,
              ref.icon
            );
          })}
        </div>
      </div>
    );
  }

  getHobbies() {
    let hobbies = [];
    hobbies.push(
      <ul key={keygen()}>
        {this.i18n.hobbies.map((hobby) => {
          return (
            <li key={keygen()}>
              <span key={keygen()} className="icon">
                {hobby.icon}
              </span>
              {hobby.title}
            </li>
          );
        })}
      </ul>
    );

    hobbies.push(
      <ul key={keygen()}>
        {hobbyList.map((d) => {
          return <li key={keygen()}>{d}</li>;
        })}
      </ul>
    );

    return (
      <div>
        <span className="title sub">
          <span className="icon">👾</span>Hobbies
        </span>
        {hobbies}
      </div>
    );
  }

  getSubjects(item) {
    return (
      <div>
        <div className="sub title">{this.titles.subjects}</div>
        <ul className="technos colled">
          {item.school.i18n.subjects.map((t) => (
            <li key={keygen()} className="techno">
              {t}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  getCertification(item) {
    let cardcontent = item.obtained ? item.degree : null;

    let cardactions = (
      <div>
        <img src={item.badgeUrl} width={64} />
        <div>
          {item.o} certified: {item.degree}
        </div>
      </div>
    );

    const avatar = (
      <Avatar sx={{ bgcolor: "cornflowerblue" }} aria-label="job">
        {Array.from(item.degree)[0]}
      </Avatar>
    );

    let v = getCard(item.degree, item.o, avatar, cardcontent, cardactions);

    return v;
  }

  getEducation(item) {
    let s = (
      <div title="desc" style={{ marginTop: "2em" }} className="company">
        <h3>
          {item.school.obtained ? (
            <li style={{ fontWeight: "bold" }}>{item.school.degree}</li>
          ) : null}
        </h3>
        <h4 className="sub title">
          <a
            href={`https://www.google.com/maps?q=${item.school.city}`}
            target="_blank"
          >
            {item.school.o}
          </a>{" "}
          - <span style={{ fontStyle: "italic" }}>{item.school.country}</span>
        </h4>

        <div>
          {item.school.i18n.faculty ? (
            <span>
              {this.titles.fac}: <i>{item.school.i18n.faculty}</i>
            </span>
          ) : null}
        </div>

        {item.school.i18n.major ? (
          <span>
            {this.titles.major}: <i>{item.school.i18n.major}</i>
          </span>
        ) : null}

        {item.school.remark ? (
          <span>
            {item.school.degree} {item.school.remark}
          </span>
        ) : null}
      </div>
    );

    let subjects = (
      <div className="blocked" style={{ columnCount: 2 }}>
        <h4>{this.titles.subjects}</h4>
        {this.getSubjects(item)}
      </div>
    );

    const title = item.school.obtained ? (
      <li style={{ fontWeight: "bold" }}>{item.school.degree}</li>
    ) : null;

    const subheader = (
      <span>
        <a
          href={`https://www.google.com/maps?q=${item.school.city}`}
          target="_blank"
        >
          {item.school.o}
        </a>{" "}
        - <span style={{ fontStyle: "italic" }}>{item.school.country}</span>
      </span>
    );

    const avatar = (
      <Avatar sx={{ bgcolor: "cornflowerblue" }} aria-label="job">
        {Array.from(item.school.degree)[0]}
      </Avatar>
    );

    const cardcontent = (
      <div>
        {item.school.i18n.faculty ? (
          <span>
            {this.titles.fac}: <i>{item.school.i18n.faculty}</i>
          </span>
        ) : null}
      </div>
    );

    const cardactions = (
      <Typography variant="body1" color="text.secondary">
        {this.getSubjects(item)}
        {item.school.i18n.major ? (
          <span>
            {this.titles.major}: <i>{item.school.i18n.major}</i>
          </span>
        ) : null}

        {item.school.remark ? (
          <span>
            {item.school.degree} {item.school.remark}
          </span>
        ) : null}
      </Typography>
    );

    let c = getCard(title, subheader, avatar, cardcontent, cardactions);
    return c;
  }

  render() {
    let contact = this.getContact();
    let personal = this.getPersonal();
    let hobbies = this.getHobbies();
    let langskill = this.getLangaugeSkills();
    let items = [];

    let ck = getCookie(this.tempkey);
    items.push(langskill);
    if (ck == 1) {
      items.push(personal);
      items.push(hobbies);
    }

    let qrcode = <QRCode size="64" value={host} />;

    let avatar = (
      <Avatar
        alt={this.props.data.perso.name}
        src="./img/unused/id.png"
        sx={{ width: 48, height: 48 }}
      />
    );

    let ref = this.getRef();
    return (
      <Card style={{}}>
        <CardHeader
          avatar={qrcode}
          title={
            <Typography gutterBottom variant="h3" component="div">
              {this.props.data.perso.name}
            </Typography>
          }
          subheader={
            <Typography
              gutterBottom
              variant="h4"
              component="div"
            >{`🧑‍💻 ${this.i18n.workTitle}`}</Typography>
          }
        />

        <CardContent>
          <Typography variant="h4" color="text.secondary">
            <div style={{ margin: "15px" }}>
              <Typography variant="h6" color="text.secondary">
                {contact}
              </Typography>
            </div>

            <div style={{ margin: "15px", columnCount: items.length }}>
              <Typography variant="h6" color="text.secondary">
                {items}
              </Typography>
            </div>

            {/*<div style={{ margin: "15px" }}>{ref}</div>*/}

            {/* {this.props.edu.map((item, index) => {
              if (item.school) return this.getEducation(item);
              if (item.certification)
                return this.getCertification(item.certification);
              return null;
            })} */}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}
